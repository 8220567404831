import { USER_ROLE } from 'configs/userRole';

class AuthService {
  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken || !this.isValidToken(accessToken)) return;
    this.setSession('accessToken', accessToken);
  };

  loginWithAuth0 = async (
    username: string,
    roleUser: string,
    code: string,
    accessToken: string,
    permission: any,
    refreshtoken: string,
  ) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
      this.setSession('refreshtoken', refreshtoken);
    }
    if (permission) {
      const permissionStringify = JSON.stringify(permission);
      this.setSession('permission', permissionStringify);
    }

    const user = {
      user: username,
      role: roleUser,
      code: code,
    };

    if (accessToken) {
      const userStringify = JSON.stringify(user);
      this.setSession('user', userStringify);
    }

    return user;
  };

  loginWithCustomerUserAuth0 = async (userData: any, accessToken: string, campaign: any, customer: any) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
    }
    const user = {
      user: userData,
      is_new: userData.is_new,
      customer_unique_id: userData.customer_unique_id,
      campaign,
      customer,
    };
    if (accessToken) {
      const userStringify = JSON.stringify(user);
      this.setSession('user', userStringify);
    }
    return user;
  };

  loginWithToken = async () => {
    return {
      user: 'tonynguyen',
    };
  };

  setSession = (key: string, accessToken: string) => {
    localStorage.setItem(key, accessToken);
  };

  logOut = () => {
    const recordedTrackerValues = localStorage.getItem('trackers');
    localStorage.clear();
    recordedTrackerValues && localStorage.setItem('trackers', recordedTrackerValues);
  };

  getUser = () => {
    const user = localStorage.getItem('user') || '';
    return user;
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isAuthenticated = () => !!this.getAccessToken();

  isValidToken = (accessToken: string | null) => {
    const expireTime = 1606275140.897;
    if (!accessToken) return false;

    const currentTime = Date.now() / 1000;

    return expireTime < currentTime;
  };
}

const authService = new AuthService();

export default authService;
