import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IMAGES_PATH } from 'configs/imagesPath';
import { completeUserProfile, handleLoginModal, loginUser, resetPasswordCustomer } from 'redux/actions/auth.action';
import './styles.scss';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
// Define the props for the Login Page component.
type ILoginProps = {
  isSigningIn?: boolean;
  isResetPasswordLoading?: boolean;
  isLoggedIn?: any;
  user?: any;
  is_new?: any;
  loginModal?: any;
};

// Define the Login Page component.
const CompleteProfileModal = ({ isSigningIn, is_new, user, loginModal, isResetPasswordLoading }: ILoginProps) => {
  // Initialize React hooks and variables.
  const [isUserResettingPassword, setIsUserResettingPassword] = useState(false);
  const [subDomain, setSubDomain] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistory();

  // Define form validation using Yup.
  const validation = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format') // Invalid email format error message
      .required('Please enter email'),
    password: Yup.string()
      .required('Please enter password')
      .min(8, 'Must be 8-15 characters and contain one uppercase, one lowercase and one number')
      .max(15, 'Must be 8-15 characters and contain one uppercase, one lowercase and one number')
      .test(
        'passwordRequirements',
        'Must contain at least one uppercase letter, one lowercase letter, and one number', // Custom error message
        (value) => [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) => pattern.test(value)),
      ),
  });

  // Configure options for the useForm hook with Yup resolver.
  const optionsForm: any = { mode: 'all', resolver: yupResolver(validation) };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(optionsForm);

  useEffect(() => {
    // Check if there's an 'id' parameter in the URL
    const searchParams = new URLSearchParams(window.location.search);

    //extract campaign name form URL
    const currentHostname = window.location.hostname;
    const hostnameParts = currentHostname.split('.');

    let campaignName = null;
    if (currentHostname != 'localhost' && hostnameParts.length) {
      campaignName = hostnameParts[0];
      setSubDomain(campaignName);
    } else {
      campaignName = searchParams.get('subdomain');
      setSubDomain(campaignName);
    }
  }, []);
  // Handle form submission.
  const handleFormSubmit = ({ email, password }: any) => {
    dispatch(loginUser(email, password, history, subDomain));
  };
  const handlePasswordReset = (e: any) => {
    e.preventDefault();
    const email = watch('email');
    dispatch(resetPasswordCustomer({ email }, history));
  };
  const loginText = {
    login: 'Welcome Back!',
    resetPassword: 'Reset Password',
  };
  const loginHelperText = {
    login: 'Please enter your details below to log in.',
    resetPassword: 'Please enter your email, we will send password reset link there.',
  };
  const handleLoginButtonClick = () => setIsUserResettingPassword(false);
  const handleResetButtonClick = () => setIsUserResettingPassword(true);
  useEffect(() => {
    setIsUserResettingPassword(false);
  }, [loginModal]);

  // Render the Login Page component.
  return (
    <Dialog open={loginModal} maxWidth={'lg'} className="modalLogin" onClose={() => dispatch(handleLoginModal(false))}>
      <div className="login-wrapper-modal">
        <IconButton className="close-icon" onClick={() => dispatch(handleLoginModal(false))}>
          <CloseIcon className="text-white" />
        </IconButton>
        <div className="signup-box">
          <h1 className="title">{isUserResettingPassword ? loginText.resetPassword : loginText.login}</h1>
          <div className="description">{isUserResettingPassword ? loginHelperText.resetPassword : loginHelperText.login}</div>
          {isUserResettingPassword ? (
            <>
              {/* Display this compoment with user is resetting password */}
              <form className="form-tag" onSubmit={handlePasswordReset}>
                {/* Display an error message if email validation fails. */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      margin="normal"
                      placeholder="Email"
                      fullWidth
                      id="email"
                      required
                      {...register('email')}
                      error={Boolean(errors.email)}
                      helperText={errors?.email?.message as any}
                    />
                  </Grid>
                </Grid>
                <div className="primary-btn">
                  {isResetPasswordLoading ? (
                    <Box className="loader">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button type="submit" className="primaryButton" fullWidth variant="contained" color="primary">
                      Send Reset Link
                    </Button>
                  )}
                </div>
              </form>
              <div className="reset-password">
                <p>
                  Go back to <span onClick={handleLoginButtonClick}>Login page</span>
                </p>
              </div>
            </>
          ) : (
            <>
              {/* Display this compoment when user is on login screen */}
              <form className="form-tag" onSubmit={handleSubmit(handleFormSubmit)}>
                {/* Display an error message if email validation fails. */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      margin="normal"
                      placeholder="Email"
                      fullWidth
                      id="email"
                      {...register('email')}
                      error={Boolean(errors.email)}
                      helperText={errors?.email?.message as any}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="password"
                      margin="normal"
                      fullWidth
                      id="password"
                      placeholder="Password"
                      {...register('password')}
                      error={Boolean(errors.password)}
                      helperText={errors?.password?.message as any}
                    />
                  </Grid>
                </Grid>
                <div className="reset-password">
                  <p>
                    Forgot password? <span onClick={handleResetButtonClick}>Reset here</span>
                  </p>
                </div>
                {/* Display a loading spinner when signing in, or a login button when not signing in. */}
                <div className="primary-btn">
                  {isSigningIn ? (
                    <Box className="loader">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button type="submit" className="primaryButton" variant="contained" color="primary">
                      Sign In
                    </Button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
        <div className="img">
          <img src={IMAGES_PATH.background.loginNewBackground} alt="loginbg" className="img-fluid" />
        </div>
      </div>
    </Dialog>
  );
};

// Map the Redux state to props for the Login Page component.
const mapStateToProps = (state: any) => ({
  isSigningIn: state.auth?.isSigningIn,
  isResetPasswordLoading: state.auth?.isResetPasswordLoading,
  is_new: state.auth?.is_new,
  user: state.auth.user,
  loginModal: state.auth.loginModal,
});

// Connect the component to Redux and export it.
export default connect(mapStateToProps)(CompleteProfileModal);
