import Swal from 'sweetalert2';
import './AlertEmpty.scss';

type IAlertProps = {
  title?: any;
  message?: any;
  button?: any;
  url?: any;
};

const AlertEmpty = ({ title, message, button, url }: IAlertProps) => {
  Swal.fire({
    title: title,
    html: message,
    customClass: {
      popup: 'popup-class',
      cancelButton: 'cancel-button-class',
      htmlContainer: 'html-class',
      title: 'title-class',
      confirmButton: 'confirm-button-class',
      closeButton: 'close-button-class',
    },
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonText: button,
    cancelButtonText: 'cancel',
    cancelButtonColor: '#D20A0A',
  }).then((result) => {
    if (result.isConfirmed && url) {
      window.location.href = `${window.location.origin}${url}`;
    }
  });
};

export default AlertEmpty;
