import mixpanel from 'mixpanel-browser';
const keyName = 'trackers';

export const mixpanelTrack = (event: any) => {
  const savedEvent = localStorage.getItem(keyName);
  const events = savedEvent ? JSON.parse(savedEvent) : {};
  const eventName = events[event] ? event : `${event}_unique`;
  mixpanel.track(eventName, {});

  if (!events[event]) {
    events[event] = true;
    localStorage.setItem(keyName, JSON.stringify(events));
  }
};
