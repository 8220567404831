import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

// Components
import App from './App';

// Styles
import './index.css';

// Performance measurement
import reportWebVitals from './reportWebVitals';

// Context
import { GlobalProvider } from 'context/GlobalContext';

// Service initialization
import initRequest from 'services/initRequest';

// Redux persist
import { PersistGate } from 'redux-persist/integration/react';

// Material-UI Theme
import { ThemeProvider } from '@mui/material';
import { theme } from 'Theme';
import { configureStore } from 'redux/store';
const container = document.getElementById('root');
const root = createRoot(container!); // Use createRoot(container!) if you use TypeScript
const { store, persistor } = configureStore(); // Create your Redux store
// Initialize any services or setup
initRequest(store);

// Render the app within the necessary providers
root.render(
  <GlobalProvider>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </PersistGate>
  </GlobalProvider>,
);

// Measure and report app performance
reportWebVitals();
