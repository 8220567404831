export const IMAGES_PATH = {
  logo: {
    logoBlue: '/assets/tlc/logo/tlc_group_logo.png',
    logo: '/assets/tlc/logo/logo.svg',
    logoWhite: '/assets/tlc/logo/logoWhite.svg',
  },
  icons: {
    bag: '/assets/tlc/icons/bag.svg',
    user: '/assets/tlc/icons/user.svg',
    togglerDark: '/assets/tlc/icons/toggler.svg',
    dashboard: '/assets/tlc/icons/dashboard.svg',
    wellness: '/assets/tlc/icons/wellness.svg',
    dining: '/assets/tlc/icons/dining.svg',
    activites: '/assets/tlc/icons/activities.svg',
    entertainment: '/assets/tlc/icons/entertainment.svg',
    travel: '/assets/tlc/icons/travel.svg',
    learning: '/assets/tlc/icons/learning.svg',
    users: '/assets/tlc/icons/User.png',
    stars: '/assets/tlc/icons/Star.png',
    money: '/assets/tlc/icons/Money.png',
    family: '/assets/tlc/icons/Family.png',
  },
  slider: {
    slide1: '/assets/tlc/banners/slider1.jpg',
    slide2: '/assets/tlc/banners/slider2.jpg',
    slide3: '/assets/tlc/banners/slider3.jpg',
    slide4: '/assets/tlc/banners/slider4.jpg',
    slide5: '/assets/tlc/banners/slider5.jpg',
    slide4Mob: '/assets/tlc/banners/slider4-mob.jpg',
  },
  background: {
    loginBackground: '/assets/tlc/backgrounds/loginBackground.jpg',
    loginNewBackground: '/assets/tlc/backgrounds/loginimg.png',
    listing: '/assets/tlc/backgrounds/listing.jpg',
    checkout: '/assets/tlc/backgrounds/checkout.jpg',
  },
  pageImage: {
    checkoutSuccess: '/assets/tlc/images/checkout-success.png',
  },
};
