import React from 'react';
import { Divider } from '@mui/material';
import './styles.scss';
import { LogogWhite } from 'configs/logo';
import Login from 'pages/Login/Login';
import { PATH_NAME } from 'configs/pathName';
import { useSelector } from 'react-redux';
import Brands from 'components/Brands';

function Footer() {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  return (
    <React.Fragment>
      <Brands />
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <ul className="quick-links">
                <li>
                  <a href={PATH_NAME.Contact_Us}>CONTACT Us</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.TERM_CONDITION}>TERMS AND CONDITIONS</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.PRIVACY_POLICY}>PRIVACY AND COOKIES POLICY</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.FAQ}>FAQS</a>
                </li>
              </ul>
              <div className="logo">
                <LogogWhite width={325} height={107} />
              </div>
              {isLoggedIn ? (
                <div className="footer-pragraph">
                  *Must be signed up for Influence Mobile and accrue enough points to redeem a Reward. Valid for U.S. only and
                  must be 18+ years old. Rewards have no cash value. The “$25 credit” approximates value of points accrued and
                  actual value may vary. Rewards are non-transferrable. Redeeming “$25 credit” Reward removes the appropriate
                  points from your account. Rewards may require a credit card. Rewards may be limited to new users only. Rewards
                  may auto-renew at the selected tier at the end of the free trial. Each Reward has its own Terms and Conditions
                  you must accept to redeem. Additional terms and conditions apply here. Void where prohibited.
                </div>
              ) : null}

              <div className="copy-right">
                <div className="copyrightText">Copyright © TLC Rewards. All Rights Reserved.</div>
                <div className="poweredby">Powered by TLC Rewards</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
