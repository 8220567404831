import axios from 'axios';
import { sessionExpired } from 'redux/actions/auth.action';
import { configureStore } from 'redux/store';

const { store } = configureStore(); // Create your Redux store
export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL;

function getAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken;
}

function setAccessToken(newAccessToken: any) {
  window.localStorage.setItem('accessToken', newAccessToken);
}

const api = axios.create({
  baseURL: API_ENDPOINT,
});

api.interceptors.request.use(async (config) => {
  config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  config.headers['Content-Type'] = 'application/json';

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Handle 401 status, perform token refresh logic, or log out
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Call your refresh token endpoint to get a new access token
        const response = await axios
          .post(`${API_ENDPOINT}/user/refresh_login`, {
            token: window.localStorage.getItem('refreshtoken'),
          })
          .then((res: any) => {
            const newAccessToken = res.data.data[0].accessToken;

            // Update the access token in the local storage
            setAccessToken(newAccessToken);

            // Update the original request with the new access token
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            window.location.reload();
            // Retry the original request
            return axios(originalRequest);
          })
          .catch((error) => {
            store.dispatch(sessionExpired() as any);
          });
      } catch (refreshError) {
        // Handle refresh token error or log the user out
        store.dispatch(sessionExpired() as any);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error.response);
  },
);
export default api;
