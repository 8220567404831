import { PATH_NAME } from 'configs/pathName';
import { Redirect } from 'react-router';
import permissionService from 'services/permissions/permissionService';

const PermissionAccess = ({ children, permissionKey, permissionType }: any) => {
  if (permissionKey) {
    if (permissionService.shouldRestrict(permissionKey, permissionType)) {
      return <Redirect to={PATH_NAME.DASHBOARD} />;
    } else {
      return <>{children}</>;
    }
  }

  return <>{children}</>;
};

export default PermissionAccess;
