import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Dialog, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IMAGES_PATH } from 'configs/imagesPath';
import { completeUserProfile } from 'redux/actions/auth.action';
import './styles.scss';

// Define the props for the Login Page component.
type ILoginProps = {
  isSigningIn?: boolean;
  isLoggedIn?: any;
  user?: any;
  is_new?: any;
};

// Define the Login Page component.
const CompleteProfileModal = ({ isSigningIn, is_new, user }: ILoginProps) => {
  // Initialize React hooks and variables.
  const dispatch = useDispatch();
  const history = useHistory();

  // Define form validation using Yup.
  const validation = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format') // Invalid email format error message
      .required('Please enter email'),
    first_name: Yup.string().required('Please enter first name'), // Required field error message
    last_name: Yup.string().required('Please enter last name'), // Required field error message
    phone: Yup.string(),
    password: Yup.string()
      .required('Please enter password')
      .min(8, 'Must be 8-15 characters and contain one uppercase, one lowercase and one number')
      .max(15, 'Must be 8-15 characters and contain one uppercase, one lowercase and one number')
      .test(
        'passwordRequirements',
        'Must contain at least one uppercase letter, one lowercase letter, and one number', // Custom error message
        (value) => [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) => pattern.test(value)),
      ),
    confirm_password: Yup.string()
      .required('Please confirm password')
      .oneOf([Yup.ref('password')], 'Passwords do not match'), // Passwords do not match error message

    zip_code: Yup.string().required('Please enter zipcode'), // Required field error message
    opt_ppolicy: Yup.bool(),
  });

  // Configure options for the useForm hook with Yup resolver.
  const optionsForm: any = {
    mode: 'all',
    resolver: yupResolver(validation),
    defaultValues: {
      opt_ppolicy: false,
    },
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(optionsForm);

  // Handle form submission.
  const handleFormSubmit = async (data: any) => {
    const { customer_unique_id, creditBalance, creditsUsed } = user.user;
    const finalData = { ...data, customer_unique_id, creditBalance, creditsUsed, is_new: false };
    dispatch(completeUserProfile(finalData));
  };

  // Render the Login Page component.
  return (
    <Dialog open={is_new} maxWidth={'lg'} className="modalLogin">
      <div className="login-wrapper-modal">
        <div className="signup-box">
          <h1 className="title">Let’s Get Started!</h1>
          <div className="description">Complete your profile and redeem your reward!</div>
          <form className="form-tag" onSubmit={handleSubmit(handleFormSubmit)}>
            {/* Display an error message if email validation fails. */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="first_name"
                  margin="normal"
                  fullWidth
                  id="first_name"
                  placeholder="First Name"
                  {...register('first_name')}
                  error={Boolean(errors.first_name)}
                  helperText={errors?.first_name?.message as any}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="last_name"
                  margin="normal"
                  fullWidth
                  placeholder="Last Name"
                  id="last_name"
                  {...register('last_name')}
                  error={Boolean(errors.last_name)}
                  helperText={errors?.last_name?.message as any}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="email"
                  margin="normal"
                  placeholder="Email"
                  fullWidth
                  id="email"
                  {...register('email')}
                  error={Boolean(errors.email)}
                  helperText={errors?.email?.message as any}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  margin="normal"
                  fullWidth
                  placeholder="Phone Number"
                  id="phone"
                  {...register('phone')}
                  error={Boolean(errors.phone)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="password"
                  margin="normal"
                  fullWidth
                  id="password"
                  placeholder="Password"
                  {...register('password')}
                  error={Boolean(errors.password)}
                  helperText={errors?.password?.message as any}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="password"
                  margin="normal"
                  fullWidth
                  placeholder="Confirm Password"
                  id="confirm_password"
                  {...register('confirm_password')}
                  error={Boolean(errors.confirm_password)}
                  helperText={errors?.confirm_password?.message as any}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="zip_code"
                  margin="normal"
                  placeholder="Zipcode"
                  fullWidth
                  id="zip_code"
                  {...register('zip_code')}
                  error={Boolean(errors.zip_code)}
                  helperText={errors?.zip_code?.message as any}
                />
              </Grid>
              <Grid xs={12} className="mt-2">
                <Controller
                  name="opt_ppolicy"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, onBlur, value, ...field } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} onChange={onChange} {...field} />}
                      label={
                        <div className="optin-toggle">
                          Yes, I would like to be contacted by TLC Worldwide group, by email, for the purposes of market research
                          in accordance with our{' '}
                          <a
                            className="hyper-link"
                            href="https://b47596f4-cffb-41a4-9099-bc648a911292.eu.websites.cosmosplatform.io/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          . I can opt-out at any time.
                        </div>
                      }
                    />
                  )}
                />
              </Grid>
              <FormGroup />
            </Grid>

            {/* Display a loading spinner when signing in, or a login button when not signing in. */}
            <div className="primary-btn">
              {isSigningIn ? (
                <Box className="loader">
                  <CircularProgress />
                </Box>
              ) : (
                <Button type="submit" className="primaryButton" variant="contained" color="primary">
                  Sign Up
                </Button>
              )}
            </div>
          </form>
        </div>
        <div className="img">
          <img src={IMAGES_PATH.background.loginBackground} alt="loginbg" className="h-100" />
        </div>
      </div>
    </Dialog>
  );
};

// Map the Redux state to props for the Login Page component.
const mapStateToProps = (state: any) => ({
  isSigningIn: state.auth?.isSigningIn,
  is_new: state.auth?.is_new,
  user: state.auth.user,
});

// Connect the component to Redux and export it.
export default connect(mapStateToProps)(CompleteProfileModal);
