import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    custom: Palette['primary'];
  }

  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}
export const theme = createTheme({
  palette: {
    primary: {
      main: '#0c4ccf',
    },
    secondary: {
      main: '#f3c42b',
    },
    info: {
      main: '#e81961',
    },
  },
});
