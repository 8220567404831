export const PERMISSION_MODULE = {
  EMAIL: 'email-template',
  PRIVACY: 'privacy-policy',
  TERMS: 'terms',
  CUSTOMER: 'customers',
  CAMPAIGN: 'campaigns',
  USER: 'user',
  REWARD: 'rewards',
  REPORT: 'reports',
  REWARDCATEGORY: 'reward-category',
  PERMISSION: 'permissions',
  OFFER: 'offers',
  FAQS: 'faq',
};

export const PERMISSION_TYPE = {
  ADD: 'add',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  LISTING: 'listing',
};
