// Navbar.tsx
import React from 'react';
import { IMAGES_PATH } from 'configs/imagesPath';
import { PATH_NAME } from 'configs/pathName';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleSignupModal, logout } from 'redux/actions/auth.action';
import { USER_ROLE } from 'configs/userRole';

import { Chip, IconButton } from '@mui/material';
import { Coin } from 'configs/icons';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';
import { mixpanelTrack } from 'helpers/mixpanelTracking';
import { useEffect } from 'react';
import { hideHeaderFoooter } from 'redux/actions/app.action';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const MainNavbar = ({ isLoggedIn, role, cartItems = [], customerCredits = 0, user, isSurvey }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === '/survey') {
      dispatch(hideHeaderFoooter(true));
    }
  }, []);

  const goToProfile = () => {
    history.push(PATH_NAME.PROFILE);
    mixpanelTrack('header_account');
  };
  const goToCart = () => {
    cartItems.length == 0 ? '/' : history.push(PATH_NAME.CART);
    mixpanelTrack('header_cart');
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="text-end m-1">
        <IconButton onClick={toggleDrawer(anchor, true)} color="primary">
          <CloseIcon />
        </IconButton>
      </div>

      <List className="mobile-menu">
        {isLoggedIn ? (
          <ListItem disablePadding>
            <ListItemButton className="mobileMenuItem">
              <ListItemIcon className="menu-iocn">
                <Coin />
              </ListItemIcon>
              <ListItemText className="link-test">{customerCredits}</ListItemText>
            </ListItemButton>
          </ListItem>
        ) : null}

        <ListItem disablePadding>
          <ListItemButton className="mobileMenuItem" onClick={goToCart}>
            <ListItemIcon className="menu-iocn">
              <div className="cart-icon">
                <img src={IMAGES_PATH.icons.bag} alt="cart-icon" />
                <div className="badge">{cartItems.length}</div>
              </div>
            </ListItemIcon>
            <ListItemText className="link-test">My Cart</ListItemText>
          </ListItemButton>
        </ListItem>
        {isLoggedIn ? (
          <ListItem disablePadding>
            <ListItemButton className="mobileMenuItem" onClick={goToProfile}>
              <ListItemIcon className="menu-iocn">
                <div className="user-icon">
                  <img src={IMAGES_PATH.icons.user} alt="user-icon" />
                </div>
              </ListItemIcon>
              <ListItemText className="link-test">{user?.first_name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem disablePadding>
            <ListItemButton className="mobileMenuItem" href={PATH_NAME.PROFILE}>
              <ListItemIcon className="menu-iocn">
                <div className="user-icon">
                  <img src={IMAGES_PATH.icons.user} alt="user-icon" />
                </div>
              </ListItemIcon>
              <ListItemText className="link-test">My Account</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      {role === USER_ROLE.ADMIN || isSurvey === true ? null : (
        <>
          <Navbar className="main-navbar d-none d-lg-block">
            <Container>
              <Navbar.Brand href={PATH_NAME.ROOT} className="d-flex align-items-center">
                <img src={'/assets/tlc/logo/logon.png'} alt="logo" className="img-fluid" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <div className="d-flex ms-auto align-items-center">
                  {isLoggedIn ? (
                    <div className="coins-wrap">
                      <div className="coins">
                        <div className="credit">{customerCredits}</div>
                        {/* <div className="coin-label">Coins</div> */}
                      </div>
                      <Coin />
                    </div>
                  ) : null}
                  <div className="cart-icon" onClick={goToCart}>
                    <img src={IMAGES_PATH.icons.bag} alt="cart-icon" />
                    <div className="badge">{cartItems.length}</div>
                  </div>
                  <div className="user-icon" onClick={goToProfile}>
                    <img src={IMAGES_PATH.icons.user} alt="user-icon" />
                    {isLoggedIn ? <div className="user-name">{user?.first_name}</div> : null}
                  </div>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Navbar className="main-navbar d-block d-lg-none">
            <Container>
              <Navbar.Brand href={PATH_NAME.ROOT} className="d-flex align-items-center">
                <img src={'/assets/tlc/logo/logon.png'} alt="logo" className="img-fluid" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <div className="d-flex ms-auto align-items-center">
                  {(['right'] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton onClick={toggleDrawer(anchor, true)} size="medium">
                        <MenuIcon fontSize="large" />
                      </IconButton>
                      <Drawer
                        className="main-navbar mobile-menu"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  role: state.auth.role,
  cartItems: state.auth.cartItems,
  customerCredits: state.auth.customerCredits,
  user: state.auth.user?.user,
  isSurvey: state.app.isSurvey,
});

export default connect(mapStateToProps)(MainNavbar);
