import { PATH_NAME } from 'configs/pathName';
import { Redirect } from 'react-router';

// configs

// services
import authService from 'services/authService';

const GuestGuard = ({ children }: any) => {
  const isAuth = authService.getAccessToken();
  if (isAuth) return <Redirect to={PATH_NAME.ROOT} />;

  return <>{children}</>;
};

export default GuestGuard;
