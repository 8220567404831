import React, { FC, useEffect, useState } from 'react';

// material core
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from 'containers/ErrorBoundary';
import { Button } from '@mui/material';
import MainNavbar from './NavBar';
import { useSelector } from 'react-redux';
import { roleSelector } from 'selectors/auth.selector';

const NavLayout: FC = ({ children }: any) => {
  const userRole = useSelector((state) => state);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const token = !!localStorage.getItem('accessToken');
  useEffect(() => {
    setIsLoggedIn(token);
  }, [token]);

  return (
    <div>
      <CssBaseline />
      <MainNavbar isLoggedIn={isLoggedIn} />
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};

export const EmptyNavLayout: FC = ({ children }: any) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default NavLayout;
