import { PATH_NAME } from 'configs/pathName';
import { USER_ROLE } from 'configs/userRole';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { handleLoginModal } from 'redux/actions/auth.action';

// services
import authService from 'services/authService';

const AuthGuard = ({ children }: any) => {
  const isAuth = authService.getAccessToken();
  const user = authService.getUser() as any;
  const dispatch = useDispatch();
  if (user) {
    const userRole = JSON.parse(user);
    if (userRole.role === USER_ROLE.ADMIN) return <Redirect to={PATH_NAME.DASHBOARD} />;
  }
  // Assuming you have a Redux state for login modal
  const isLoginModalOpen = useSelector((state: any) => state.auth.isLoginModalOpen);

  useEffect(() => {
    // If the user is not authenticated, open the login modal
    if (!isAuth) {
      if (!isLoginModalOpen) {
        <Redirect to={PATH_NAME.ROOT} />;
        dispatch(handleLoginModal(true));
      }
    }
  }, [isAuth, isLoginModalOpen, dispatch]);

  if (user) {
    const userRole = JSON.parse(user);
    if (userRole.role === USER_ROLE.ADMIN) {
      return <Redirect to={PATH_NAME.DASHBOARD} />;
    }
  }

  if (!isAuth) {
    // You may choose to render something specific here when not authenticated
    return <Redirect to={PATH_NAME.ROOT} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
