import React from 'react';
import {
  NationalActivities,
  NationalDining,
  NationalEntertainment,
  NationalLearning,
  NationalService,
  NationalTravel,
  NationalWellNess,
} from 'configs/icons';
import './styles.scss';
import Carousel from 'react-multi-carousel';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Brands() {
  return (
    <section className="brands">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
              autoPlay={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              arrows={false}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="text-center">
                <NationalWellNess />
              </div>
              <div className="text-center">
                <NationalLearning />
              </div>
              <div className="text-center">
                <NationalService />
              </div>
              <div className="text-center">
                <NationalEntertainment />
              </div>
              <div className="text-center">
                <NationalDining />
              </div>
              <div className="text-center">
                <NationalTravel />
              </div>
              <div className="text-center">
                <NationalActivities />
              </div>
            </Carousel>
            {/* <div className="d-inline-flex justify-content-between align-content-center">

            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brands;
